import ViewSinglePost from "@/components/socials/view-single-post"
import useQuerySocial from "@/hooks/social/useQuerySocial"
import useQueryParams from "@/hooks/useQueryParams"
import { Stack } from "@mui/material"

const ListPosts = ({ posts = [] }) => {
    const { editPost } = useQuerySocial()
    const { removeAllQueryParams } = useQueryParams()
    return (
        <Stack
            sx={{
                minHeight: "422px",
            }}
            width={"100%"}
        >
            <Stack
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: 1,
                    width: "100%",
                    "@media(max-width: 767px)": {
                        gridTemplateColumns: "repeat(2, 10fr)",
                    },
                }}
            >
                {posts?.map((post) => (
                    <ViewSinglePost
                        description={post?.description}
                        imageCover={post?.thumbnail}
                        facebook={post?.provider.includes("facebook")}
                        google={post?.provider.includes("google")}
                        instagram={post?.provider.includes("instagram")}
                        postId={post?.id}
                        status={post?.status}
                        date={post?.publishAt[0]?.originalDate}
                        onPostClick={() => {
                            removeAllQueryParams()
                            editPost(post?.id)
                        }}
                        key={post?.id}
                    />
                ))}
            </Stack>
        </Stack>
    )
}

export default ListPosts
