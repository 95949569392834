import BusinessAutocomplete from "@/components/autocomplete/business-autocomplete"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { BoxUploadImage } from "@/components/base/box-upload-image"
import { DatePickerCustom } from "@/components/base/date-picker"
import BaseInput from "@/components/base/input"
import BaseSelect from "@/components/base/select"
import InputEmojiCustom from "@/components/input-emoji"
import SocialStore from "@/store/social"
import UiStore from "@/store/ui"
import { generateTimeSlots, getNextAvailableTimes } from "@/utils"
import {
    Box,
    Button,
    CardMedia,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
} from "@mui/material"
import dayjs from "dayjs"
import { Fragment, useMemo } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { AlertSocialAccountNotConnected } from "../alert-account-not-connected"
import { AlertErrorSocialProvider } from "../alert-error"
import { AlertPostError } from "../alert-post-error"
import { useStyles, useStylesBtnProvider } from "../style"
import usePostForm from "./useForm"

const SocialPostForm = () => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { preferredLanguage } = UiStore()
    const { errorPost, showPostAlert, selectedBusinesses } = SocialStore()

    const {
        formState,
        formValues,
        socialProvider,
        setDescription,
        onBusinessChange,
        handleProvider,
        includeProvider,
        handleMedias,
        control,
        onChangeDate,
        handleSubmit,
        onSubmit,
        handleRemoveMedia,
        errorUpload,
        isOpenMedia,
        handleModalMedia,
        loading,
        tempPost,
        setErrorUpload,
        handleSaveInDraft,
        loadingDraft,
        isEditable,
        postShoudHaveCallToAction,
        setUrl,
        validDraft,
        isInvalidMedias,
        isModify,
        handleModify,
        handleReusePost,
        sendPostError,
        handleDeletePost,
        isSingleLocation,
        setAddLink,
        addLink,
        handleRemoveLink,
        minDate,
        selectedDate,
    } = usePostForm()

    const { classes: classesBtn } = useStylesBtnProvider({ props: { socialProvider: socialProvider } })
    const selectBusinessLabel = useMemo(() => {
        if (formValues?.locationIds?.length === 0 || formValues?.locationIds?.length > 1) {
            return t("BUSINESSES.TITLE")
        }
        return `${t("BUSINESSES.TITLE_SINGULAR")}`
    }, [formValues?.locationIds?.length, t])

    const labelBtnSubmit = useMemo(() => {
        if (errorPost) return t("SOCIALS.RETRY")
        else if (formValues?.addDate) return t("SOCIALS.PROGRAM")
        return t("SOCIALS.POST_NOW")
    }, [errorPost, formValues?.addDate, t])

    return (
        <Box
            className="inputs-container container-row"
            sx={{
                width: "100%",
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack className="inputs-wrapper" gap={2}>
                    <Stack className={classes.headerPost}>
                        {!isSingleLocation && (
                            <Box className="input-business">
                                <BusinessAutocomplete
                                    required={true}
                                    label={selectBusinessLabel}
                                    defaultValue={formValues?.locationIds}
                                    selectionChange={onBusinessChange}
                                    disabled={!isEditable}
                                />
                            </Box>
                        )}
                        <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                            <Stack
                                className={classesBtn.blocProvider}
                                flexDirection={"row"}
                                gap={0.5}
                                sx={{
                                    "& svg": {
                                        color: "transparent",
                                    },
                                }}
                            >
                                <IconButton
                                    className="btn-facebook"
                                    disabled={!socialProvider?.facebook || !isEditable}
                                    onClick={() => handleProvider("facebook")}
                                    sx={{
                                        border: includeProvider("facebook") ? "1px solid #0180FF" : "none",
                                    }}
                                >
                                    <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                                        {!socialProvider?.facebook ? (
                                            <Icon name="greyFacebook" />
                                        ) : (
                                            <Icon
                                                name={includeProvider("facebook") ? "coloredFacebook" : "greyFacebook"}
                                            />
                                        )}
                                        {socialProvider?.numberFacebookConnected > 0 &&
                                            formValues?.locationIds?.length > 1 && (
                                                <Typography
                                                    variant="body1"
                                                    color={
                                                        includeProvider("facebook") ? "text.secondary" : "text.disabled"
                                                    }
                                                >
                                                    {socialProvider?.numberFacebookConnected}
                                                </Typography>
                                            )}
                                    </Stack>
                                </IconButton>
                                <IconButton
                                    disabled={!socialProvider?.instagram || !isEditable}
                                    className="btn-instagram"
                                    sx={{
                                        border: includeProvider("instagram") ? "1px solid #0180FF" : "none",
                                    }}
                                    onClick={() => handleProvider("instagram")}
                                >
                                    <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                                        {!socialProvider?.instagram ? (
                                            <Icon name="greyInstagram" />
                                        ) : (
                                            <Icon
                                                name={
                                                    includeProvider("instagram") ? "coloredInstagram" : "greyInstagram"
                                                }
                                            />
                                        )}
                                        {socialProvider?.numberInstagramConnected > 0 &&
                                            formValues?.locationIds?.length > 1 && (
                                                <Typography
                                                    variant="body1"
                                                    color={
                                                        includeProvider("instagram")
                                                            ? "text.secondary"
                                                            : "text.disabled"
                                                    }
                                                >
                                                    {socialProvider?.numberInstagramConnected}
                                                </Typography>
                                            )}
                                    </Stack>
                                </IconButton>
                                <IconButton
                                    disabled={!socialProvider?.google || !isEditable}
                                    className="btn-google"
                                    sx={{
                                        border: includeProvider("google") ? "1px solid #0180FF" : "none",
                                    }}
                                    onClick={() => handleProvider("google")}
                                >
                                    <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                                        {!socialProvider?.google ? (
                                            <Icon name="greyGoogle" />
                                        ) : (
                                            <Icon name={includeProvider("google") ? "coloredGoogle" : "greyGoogle"} />
                                        )}
                                        {socialProvider?.numberGoogleConnected > 0 &&
                                            formValues?.locationIds?.length > 1 && (
                                                <Typography
                                                    variant="body1"
                                                    color={
                                                        includeProvider("google") ? "text.secondary" : "text.disabled"
                                                    }
                                                >
                                                    {socialProvider?.numberGoogleConnected}
                                                </Typography>
                                            )}
                                    </Stack>
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Stack>
                    {/* {haveMisingProvider?.length > 0 && (
                        <Stack>
                            <AlertSocialProvider />
                        </Stack>
                    )} */}
                    {sendPostError && <AlertPostError />}
                    {/* <AlertSocialAccountDisconnected /> */}
                    {selectedBusinesses?.length > 0 && showPostAlert && (
                        <>
                            {(socialProvider?.oneOfFacebookNotConnected ||
                                socialProvider?.oneOfGoogleNotConnected ||
                                socialProvider?.oneOfInstagramNotConnected) && <AlertSocialAccountNotConnected />}
                            {/* {!socialProvider?.hasFacebook && <AlertProviderConnection provider="facebook" />}
                            {!socialProvider?.hasGoogle && <AlertProviderConnection provider="google" />}
                            {!socialProvider?.hasInstagram && <AlertProviderConnection provider="instagram" />} */}
                        </>
                    )}

                    <Stack className={classes.blocTextarea}>
                        <InputEmojiCustom
                            text={formValues?.description}
                            setText={setDescription}
                            isModify={false}
                            responseByAi={false}
                            isLoading={false}
                            loadingAI={false}
                            maxLength={1500}
                            isLimitExceeded={false}
                            suggest={false}
                            displayBtn={false}
                            placeholder={t("SOCIALS.PLACEHOLDER_INPUT")}
                            disabled={!isModify && !isEditable}
                        />
                    </Stack>
                    {postShoudHaveCallToAction && (
                        <>
                            {!addLink && (
                                <Stack width={"fit-content"}>
                                    <Button
                                        variant="text"
                                        className="w-auto"
                                        onClick={() => setAddLink(true)}
                                        disabled={!isEditable}
                                    >
                                        {t("SOCIALS.ADD_LINK")}
                                    </Button>
                                </Stack>
                            )}
                            {addLink && (
                                <Stack flexDirection={"row"} gap={1}>
                                    <BaseInput
                                        disabled={!isEditable}
                                        label={t("SOCIALS.LINK")}
                                        value={formValues?.url}
                                        onChange={(e) => setUrl((e.target.value || "").trim())}
                                        helperText={formState?.errors["url"] && t("SOCIALS.URL.INVALID_LINK")}
                                        error={!!formState?.errors["url"]}
                                        max={1945}
                                        endAdornment={
                                            formValues?.provider?.includes("instagram") && (
                                                <CustomTooltip title={t("SOCIALS.URL.TOOLTIP")}>
                                                    <IconButton className="without-hover">
                                                        <Icon name="instagramInfo" />
                                                    </IconButton>
                                                </CustomTooltip>
                                            )
                                        }
                                        sx={{
                                            width: "100%",
                                            "& svg": {
                                                color: "transparent",
                                            },
                                        }}
                                    />
                                    <IconButton
                                        className="without-hover"
                                        disabled={!isEditable}
                                        onClick={handleRemoveLink}
                                    >
                                        <Icon name="delete1" sx={{ color: "error.main" }} />
                                    </IconButton>
                                </Stack>
                            )}
                        </>
                    )}

                    <Stack flexDirection={isInvalidMedias ? "column" : "row"} gap={isInvalidMedias ? "4px" : 1}>
                        {isEditable && (
                            <>
                                <BoxUploadImage
                                    isOpen={isOpenMedia}
                                    mediaNumber={tempPost?.mediasLocal?.length || tempPost?.medias?.length || 0}
                                    handleMedias={handleMedias}
                                    errorUpload={errorUpload}
                                    handleModalMedia={handleModalMedia}
                                    setErrorUpload={setErrorUpload}
                                    error={isInvalidMedias}
                                />
                                {isInvalidMedias && (
                                    <Box>
                                        <Typography
                                            component={"p"}
                                            className="helper-text"
                                            variant="caption"
                                            sx={{
                                                color: "error.main",
                                            }}
                                        >
                                            {t("SOCIALS.MUST_ADD_MEDIAS_INSTAGRAM")}
                                        </Typography>
                                    </Box>
                                )}
                            </>
                        )}

                        {tempPost?.mediasLocal?.length > 0 && (
                            <Stack gap={{ xs: 1, sm: 2 }} direction={"row"} flexWrap="wrap">
                                {tempPost?.mediasLocal?.map((media, k) => (
                                    <Fragment key={media.file.name}>
                                        {media.file && media.type === "PHOTO" && (
                                            <Box className={classes.imageWrapper}>
                                                <img
                                                    src={URL.createObjectURL(media.file)}
                                                    alt={media.file?.name}
                                                    height={98}
                                                    width={98}
                                                />
                                                {isEditable && (
                                                    <IconButton
                                                        className="btn-close"
                                                        onClick={() => handleRemoveMedia(media)}
                                                    >
                                                        <Icon name="close" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        )}
                                        {media.file && media.type === "VIDEO" && (
                                            <Box className={classes.imageWrapper}>
                                                <CardMedia
                                                    style={{ backgroundColor: "gray", borderRadius: 8 }}
                                                    height={98}
                                                    width={98}
                                                    component="video"
                                                    image={URL.createObjectURL(media.file)}
                                                />
                                                {isEditable && (
                                                    <IconButton
                                                        className="btn-close"
                                                        onClick={() => handleRemoveMedia(media)}
                                                    >
                                                        <Icon name="close" className="btn-close" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        )}
                                    </Fragment>
                                ))}
                            </Stack>
                        )}
                        {tempPost?.medias && tempPost?.medias?.length > 0 && (
                            <Stack gap={{ xs: 1, sm: 2 }} direction={"row"} flexWrap="wrap">
                                {tempPost?.medias?.map((media, k) => (
                                    <Fragment key={k}>
                                        {media.type === "PHOTO" && (
                                            <Box className={classes.imageWrapper}>
                                                <img src={media.url} alt={media.file?.name} height={98} width={98} />
                                                {isEditable && (
                                                    <IconButton
                                                        className="btn-close"
                                                        onClick={() => handleRemoveMedia(media)}
                                                    >
                                                        <Icon name="close" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        )}
                                        {media.type === "VIDEO" && (
                                            <Box className={classes.imageWrapper}>
                                                <CardMedia
                                                    style={{ backgroundColor: "gray", borderRadius: 8 }}
                                                    height={98}
                                                    width={98}
                                                    component="video"
                                                    image={media.url}
                                                />
                                                {isEditable && (
                                                    <IconButton
                                                        className="btn-close"
                                                        onClick={() => handleRemoveMedia(media)}
                                                    >
                                                        <Icon name="close" className="btn-close" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        )}
                                    </Fragment>
                                ))}
                            </Stack>
                        )}
                    </Stack>

                    <Stack>
                        <Controller
                            name="addDate"
                            control={control}
                            defaultValue={formValues?.addDate ?? false}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={!isEditable}
                                            checked={value ?? false}
                                            onChange={(e) => onChange(e.target.checked)}
                                        />
                                    }
                                    label={
                                        <Stack direction="row" alignItems="center" spacing={1} data-e2e="add-date">
                                            <Typography color="action.active">{t("SOCIALS.ADD_DATE")}</Typography>
                                        </Stack>
                                    }
                                    sx={{ width: "fit-content" }}
                                />
                            )}
                        />
                        {formValues?.addDate && (
                            <Stack flexDirection="row" gap={2} width={"100%"}>
                                <DatePickerCustom
                                    desktopModeMediaQuery="@media anypointer:fine"
                                    label={t("INPUT.DATE_LABEL")}
                                    preferredLanguage={preferredLanguage}
                                    sx={{ width: "100%" }}
                                    defaultValue={formValues?.date ? dayjs(formValues?.date) : null}
                                    readOnly={true}
                                    shrink={formValues?.date ? true : false}
                                    showLabelStar={true}
                                    value={formValues?.date ? dayjs(formValues?.date) : null}
                                    onChange={(value) => onChangeDate(value)}
                                    minDate={minDate}
                                    disabled={!isEditable}
                                    noBottomBorder={false}
                                    closeOnSelect={true}
                                />
                                <BaseSelect
                                    optionsAvailable={getNextAvailableTimes(selectedDate) || []}
                                    options={generateTimeSlots("00:00", "24:00", 30)}
                                    controlName="hour"
                                    label={t("INPUT.HOUR_LABEL")}
                                    defaultValue={formValues?.hour}
                                    control={control}
                                    disabled={!isEditable}
                                    formStateError={!!formState?.errors?.hour}
                                />
                            </Stack>
                        )}
                    </Stack>
                    {errorPost && <AlertErrorSocialProvider />}
                    {isEditable && (
                        <Stack className={classes.btnAction}>
                            {!errorPost && (
                                <Button
                                    variant="outlined"
                                    type="button"
                                    disabled={!validDraft || loadingDraft || loading}
                                    sx={{ boxShadow: "none" }}
                                    onClick={handleSaveInDraft}
                                >
                                    {loadingDraft && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                    {t("SOCIALS.SAVE_IN_DRAFT")}
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={!formState?.isValid || loading || loadingDraft}
                            >
                                {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {labelBtnSubmit}
                            </Button>
                        </Stack>
                    )}
                    {!isEditable && !sendPostError && (
                        <Stack className={classes.btnAction}>
                            <Button
                                variant="contained"
                                type="button"
                                color="secondary"
                                disabled={true}
                                sx={{ boxShadow: "none" }}
                                onClick={() => (isModify ? handleModify(false) : handleModify(true))}
                            >
                                {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {!isModify ? t("CAMPAIGN.MODIFY") : t("REPUTATION.CANCEL")}
                            </Button>

                            <Button variant="contained" type="button" onClick={() => handleReusePost(tempPost)}>
                                {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {t("SOCIALS.REUSE")}
                            </Button>
                        </Stack>
                    )}
                    {sendPostError && (
                        <Stack className={classes.btnAction}>
                            <Button
                                variant="outlined"
                                type="button"
                                color="error"
                                sx={{ boxShadow: "none" }}
                                onClick={() => handleDeletePost()}
                            >
                                {t("CAMPAIGN.REMOVE")}
                            </Button>

                            <Button variant="contained" type="submit">
                                {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {t("SOCIALS.LIVE_W_ERROR")}
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </form>
        </Box>
    )
}

export default SocialPostForm
