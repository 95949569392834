import BusinessAutocomplete from "@/components/autocomplete/business-autocomplete"
import BaseInputPhone from "@/components/base/BaseInputPhone"
import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import RadioTabButton, { DataItem } from "@/components/base/radio-tab-buttons"
import UploadFile from "@/components/base/upload-file/UploadFile"
import SelectLang, { FlagOptionType } from "@/components/select/select-lang"
import TableCampaignUploaded from "@/components/table/table-campaign-uploaded"
import useFirstStepLogic from "@/hooks/campaign/first-step/useLogic"
import useCampaignMemo from "@/hooks/campaign/useCampaignMemo"
import useCampaignUpload from "@/hooks/campaign/useCampaignUpload"
import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import useFormater from "@/hooks/useFormater"
import ListRecipient from "@/pages/campaign/list-recipient"
import CampaignStore from "@/store/campaign"
import CampaignReviewsStore from "@/store/campaign-reviews"
import useLocationStore from "@/store/location"
import UiStore from "@/store/ui"
import { SelectType } from "@/types/select"
import { isValidEmail } from "@/utils"
import { Button, Divider, Paper, Stack, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
// import Divider from "@mui/material/Divider"

import FormControlLabel from "@mui/material/FormControlLabel"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import Overviews from "../overviews"
import useStyles, { radioTabButtonSx } from "../styles"
import UploadMessage from "./upload-message"
type FirstStepCampaignOptionnalProps = {
    defaultType?: number
}

type FirstStepCampaignProps = {
    control: any
    formValue: any
    errors: any
    setValue: (key: string, value: any) => void
    onRequestCredit: () => void
    handleGetAllReviews: (page: number) => void
    disableButton?: boolean
    onSubmit: () => void
} & Partial<FirstStepCampaignOptionnalProps>

const FirstStepCampaign = ({
    control,
    formValue,
    errors,
    defaultType = 0,
    setValue,
    onRequestCredit,
    handleGetAllReviews,
    disableButton,
    onSubmit,
}: FirstStepCampaignProps) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { update: updateReviews, selectedReview, resetReviews } = CampaignReviewsStore()
    const { pagination, update, contacts, creditFetched, openUpload, defaultFlag } = CampaignStore()
    const { isPhoneValid } = usePhoneInputLogic()
    const { internationalPhone, checkContactsIfExists } = useFormater()
    const { preferredLanguage } = UiStore()
    const [openListRecipient, setOpenListRecipient] = useState<boolean>(false)
    const { businesses } = useLocationStore()
    const { showUpload, closeUpload, readFile } = useCampaignUpload()
    const { hasCredit } = useCampaignMemo({ formValue })

    const langOfCountryName = useMemo(() => {
        return preferredLanguage?.toLowerCase() || "fr-ca"
    }, [preferredLanguage])

    const radioTabButtons = useMemo<DataItem[]>(() => {
        return [{ label: t("CAMPAIGN.SMS") }, { label: t("CAMPAIGN.EMAIL") }]
    }, [t])

    const { options, creditEmail, creditSms, handleViewChange } = useFirstStepLogic({
        setValue,
    })

    const handleBackRecipient = () => {
        resetReviews()
        setOpenListRecipient(false)
    }

    useEffect(() => {
        if (options?.length === 0 && options?.[0]) {
            setValue("business", options[0].value)
        }
    }, [options, setValue])

    const typeLabel = useMemo(() => {
        return formValue?.type === "sms" ? t("CAMPAIGN.PHONE_NUMBER") : t("LABEL.EMAIL")
    }, [formValue?.type, t])

    const handleAddSingleContact = useCallback(() => {
        const newContacts = [
            ...contacts,
            {
                uid: uuidv4(),
                [t("CAMPAIGN.FIRSTNAME")]: formValue?.firstname,
                [t("CAMPAIGN.LASTNAME")]: formValue?.lastname,
                [typeLabel]:
                    formValue?.type === "sms"
                        ? internationalPhone(formValue?.phone, formValue?.country || "CA")
                        : formValue?.email,
            },
        ]
        update({
            contacts: newContacts,
            pagination: {
                ...pagination,
                rows: newContacts,
                total_count: newContacts.length,
            },
        })

        setValue("firstname", "")
        setValue("lastname", "")
        setValue("email", "")
        setValue("phone", "")
    }, [
        t,
        contacts,
        typeLabel,
        pagination,
        formValue?.firstname,
        formValue?.lastname,
        formValue?.type,
        formValue?.phone,
        formValue?.country,
        formValue?.email,
        internationalPhone,
        update,
        setValue,
    ])

    const handleFileChange = useCallback(
        (newFile: File) => {
            if (newFile) {
                readFile({
                    file: newFile,
                    country: formValue?.location?.country,
                    type: formValue?.type,
                    typeLabel,
                })
            }
        },
        [formValue?.type, formValue?.country, typeLabel, readFile]
    )

    const canAdd = useMemo(() => {
        if (!hasCredit) {
            return false
        }
        if (
            checkContactsIfExists({
                rows: contacts,
                type: formValue?.type,
                value: formValue?.type === "sms" ? formValue?.phone : formValue?.email,
                typeLabel,
                countryCode: formValue?.country,
            })
        ) {
            return false
        }
        if (formValue?.type === "sms") {
            return !!formValue?.phone && isPhoneValid(formValue?.phone) && !/^\+\d{1,4}$/.test(formValue?.phone)
        }
        return formValue?.email && isValidEmail(formValue?.email)
    }, [
        hasCredit,
        checkContactsIfExists,
        contacts,
        formValue?.type,
        formValue?.phone,
        formValue?.email,
        formValue?.country,
        typeLabel,
        isPhoneValid,
    ])

    const emailError = useMemo(() => {
        return errors?.email?.message || (!isValidEmail(formValue?.email) && t("USERS.INVALID_FORMAT_EMAIL"))
    }, [errors?.email?.message, formValue?.email, t])

    const disabled = useMemo(() => {
        return !formValue?.business || (creditFetched && !formValue?.hasCredit)
        // || formValue?.remainingCreditLessThanContacts
    }, [formValue, creditFetched])

    return (
        <Stack className={classes.stepBloc}>
            <Paper className={classes.initialWrapper}>
                <Stack className={`${classes.wrapperBloc} radiusTop ${options?.length > 1 ? "multiple" : ""}`}>
                    <Stack alignContent={"center"} className="subTitle">
                        <Typography variant="h2">{t("CAMPAIGN.NEW_CAMPAIGN")}</Typography>
                    </Stack>
                    <Stack flexDirection={"row"} gap={2} className="campaign-action">
                        {businesses?.length > 1 && (
                            <Stack className="input-business">
                                <Controller
                                    render={({ field }) => (
                                        <BusinessAutocomplete
                                            defaultValue={field?.value}
                                            label={t("CAMPAIGN.BUSINESS")}
                                            selectionChange={(ids: SelectType) => {
                                                field.onChange(ids?.value)
                                            }}
                                            multiple={false}
                                        />
                                    )}
                                    control={control}
                                    name="business"
                                />
                            </Stack>
                        )}

                        <Stack className="select-lang">
                            <SelectLang
                                defaultValue={formValue?.lang}
                                options={[
                                    {
                                        label: t("CAMPAIGN.LANGS.FR"),
                                        value: "fr-FR",
                                        // flag: "flagFr",
                                    },
                                    {
                                        label: t("CAMPAIGN.LANGS.EN"),
                                        value: "en",
                                        // flag: "flagUs",
                                    },
                                ]}
                                label={t("CAMPAIGN.LABEL_LANG")}
                                onItemClicked={(option: FlagOptionType) => setValue("lang", option?.value)}
                            ></SelectLang>
                        </Stack>
                        <Stack flexDirection={"row"} gap={2} data-testid="radio-choice" data-e2e="select-campaign">
                            <RadioTabButton
                                data={radioTabButtons}
                                defaultActive={defaultType}
                                sx={radioTabButtonSx}
                                onChange={handleViewChange}
                            />
                            <Stack flexDirection={"row"} gap={2} justifyContent={"center"} alignItems={"center"}>
                                <Stack flexDirection={"row"} alignItems="center" gap={0.5} data-testid="sms">
                                    <Icon name="messageText" sx={{ color: "action.active" }} />
                                    <Typography component={"span"}>{creditSms}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"} alignItems="center" gap={0.5} data-testid="email">
                                    <Icon name="mail" />
                                    <Typography component={"span"}>{creditEmail}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                {formValue?.business && creditFetched && !formValue?.hasCredit && (
                    <Paper className={classes.noMoreCredit}>
                        <Stack className={"content"} gap={1}>
                            <Stack flexDirection={"column"} gap={"4px"}>
                                <Typography
                                    variant="h1"
                                    component={"span"}
                                    data-e2e="popup-need-reco"
                                    sx={{ fontSize: 24, textAlign: "left", width: "100%" }}
                                >
                                    {t("CAMPAIGN.MISSING_CREDITS")}
                                </Typography>
                                <Typography sx={{ color: "action.active", fontSize: 14 }}>
                                    {t("CAMPAIGN.ZERO_CREDIT")}
                                </Typography>
                            </Stack>
                            <Button onClick={onRequestCredit} color="primary" variant="contained">
                                {t("CAMPAIGN.ASK_CREDITS")}
                            </Button>
                        </Stack>
                    </Paper>
                )}
                <Divider />
                <Stack sx={{ borderRadius: 0, padding: "16px" }} gap={2}>
                    <Stack>
                        <Typography variant="subtitle1">{t("CAMPAIGN.ADD_CONTACTS")}</Typography>
                    </Stack>
                    <Stack className={classes.inputBloc}>
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    label={t("CAMPAIGN.FIRSTNAME")}
                                    id="firstname"
                                    disabled={disabled}
                                    {...field}
                                />
                            )}
                            name="firstname"
                            control={control}
                        />
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    label={t("CAMPAIGN.LASTNAME")}
                                    id="lastname"
                                    disabled={disabled}
                                    {...field}
                                />
                            )}
                            name="lastname"
                            control={control}
                        />

                        {defaultType === 0 && (
                            <BaseInputPhone
                                control={control}
                                name="phone"
                                required
                                value={formValue?.phone}
                                label={t("CAMPAIGN.PHONE_NUMBER")}
                                onlyCountries={["CA", "US", "FR", "BE"]}
                                lang={langOfCountryName}
                                defaultCountry={defaultFlag || "CA"}
                                onChange={(info) => {
                                    // setValue("phone", info?.numberValue)
                                    setValue("country", info?.countryCode)
                                }}
                                error={!isPhoneValid(formValue?.phone)}
                                errorLabel={t("BUSINESSES.EDIT_MAIN_INFO.PHONE_INVALID")}
                                disabled={disabled}
                            />
                        )}
                        {defaultType === 1 && (
                            <Controller
                                render={({ field }) => (
                                    <BaseInput
                                        type="email"
                                        label={t("LABEL.EMAIL")}
                                        required
                                        showLabelStar
                                        autoComplete="on"
                                        id="email"
                                        {...field}
                                        error={errors?.email || !isValidEmail(formValue?.email)}
                                        helperText={emailError}
                                        disabled={disabled}
                                    />
                                )}
                                control={control}
                                name="email"
                            />
                        )}
                        <Button
                            id="add-contact"
                            className="btn-add btn-mobile"
                            color="secondary"
                            disabled={!canAdd}
                            onClick={handleAddSingleContact}
                        >
                            <Icon name="plus" />
                            <Typography component={"span"} variant="body1">
                                {t("BUSINESSES.EDIT_ATTRIBUTES.ADD")}
                            </Typography>
                        </Button>
                    </Stack>
                    <Stack>
                        <Button
                            className="btn-import btn-mobile"
                            variant={"text"}
                            onClick={showUpload}
                            color="primary"
                            disabled={disabled}
                        >
                            <Icon name="upload" sx={{ color: "white" }} />
                            <Typography component={"span"}>{t("CAMPAIGN.IMPORT")}</Typography>
                        </Button>
                    </Stack>
                </Stack>
                {contacts?.length > 0 && (
                    <Stack sx={{ borderRadius: 0, padding: "0 16px" }} className={classes.tableWrapper}>
                        <TableCampaignUploaded
                            typeLabel={typeLabel}
                            campaignType={formValue?.type || "sms"}
                            countryCode={formValue?.country || "CA"}
                            className="table-contact"
                        />
                    </Stack>
                )}

                <Stack
                    sx={{
                        padding: "16px",
                    }}
                >
                    <Controller
                        name="confirmation"
                        control={control}
                        defaultValue={formValue?.confirmation ?? false}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={value ?? false}
                                        onChange={(e) => onChange(e.target.checked)}
                                        disabled={disabled}
                                    />
                                }
                                label={
                                    <Stack direction="row" alignItems="center" spacing={1} data-e2e="confirm">
                                        <Typography
                                            color="action.active"
                                            sx={{
                                                fontSize: "14px",
                                            }}
                                        >
                                            {t("CAMPAIGN.CONFIRMATION")}
                                            <span className="MuiFormControlLabel-asterisk">*</span>
                                        </Typography>
                                    </Stack>
                                }
                            />
                        )}
                    />
                </Stack>
                <Divider />

                <Stack className={`${classes["firstStep-action"]} radiusBottom`}>
                    <Button
                        className="btn-next"
                        variant="contained"
                        color="primary"
                        disabled={disableButton}
                        onClick={onSubmit}
                        sx={{
                            padding: "8px 16px",
                        }}
                    >
                        {t("CAMPAIGN.CONTINUE")}
                    </Button>
                </Stack>
            </Paper>

            <Overviews
                handleShowDetails={(review) => {
                    updateReviews({ selectedReview: review })
                    setOpenListRecipient(true)
                }}
                handleGetAllReviews={handleGetAllReviews}
                isSingleLocation={options?.length === 1}
            />

            <UploadFile
                open={openUpload}
                close={closeUpload}
                setFile={handleFileChange}
                title={t("BUSINESSES.EDIT_LOGO.ADD_FILE")}
                accept=".csv,application/vnd.ms-excel,.xlt,application/vnd.ms-excel,.xla,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xltx,application/vnd.openxmlformats-officedocument.spreadsheetml.template,.xlsm,application/vnd.ms-excel.sheet.macroEnabled.12,.xltm,application/vnd.ms-excel.template.macroEnabled.12,.xlam,application/vnd.ms-excel.addin.macroEnabled.12,.xlsb,application/vnd.ms-excel.sheet.binary.macroEnabled.12,text/csv"
                replace=".csv"
                subTitle={
                    <>
                        <Typography
                            component="span"
                            className="image-type"
                            variant="caption"
                            sx={{
                                display: "block",
                                color: "#68738D",
                                lineHeight: "24px",
                                fontSize: "16px",
                            }}
                        >
                            {t("CAMPAIGN.UPLOAD.COLUMNS")}
                        </Typography>
                        <Typography
                            component="span"
                            className="image-type"
                            variant="caption"
                            sx={{
                                display: "block",
                                color: "#68738D",
                                lineHeight: "24px",
                                fontSize: "16px",
                            }}
                        >
                            <strong>{t("CAMPAIGN.UPLOAD.INFOS")}</strong>
                        </Typography>
                        <Typography
                            component="span"
                            className="image-type"
                            variant="caption"
                            sx={{
                                display: "block",
                                color: "#68738D",
                                lineHeight: "24px",
                                fontSize: "16px",
                            }}
                        >
                            {t("CAMPAIGN.UPLOAD.CONTACT")}
                        </Typography>
                    </>
                }
            >
                <UploadMessage />
            </UploadFile>
            {selectedReview && (
                <ListRecipient open={openListRecipient} onClose={handleBackRecipient} review={selectedReview} />
            )}
        </Stack>
    )
}

export default FirstStepCampaign
