import { SxProps } from "@mui/material"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    containerDrawer: {
        padding: 0,
        width: "100%",
        boxShadow: "none",
        gap: 16,
        backgroundColor: theme.palette.background.default,
        "@media (min-width: 768px)": {
            height: "100%",
            width: "calc(100% - 251px)",
        },
        "& .children-wrapper": {
            padding: 8,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            "@media (min-width: 768px)": {
                height: "100%",
                padding: 24,
                paddingBottom: 16,
                gap: 24,
            },
            form: {
                padding: 0,
                height: "auto",
            },
            "& .mention-blue": {
                fontFamily: "Poppins",
                color: theme.palette.action.active,
                p: {
                    margin: 0,
                },
                "& .mention": {
                    border: "none",
                    color: theme.palette.primary.main,
                },
            },
        },
    },
    containerSuccess: {
        padding: 0,
        width: "100%",
        boxShadow: "none",
        gap: 16,
        backgroundColor: theme.palette.background.default,
        "@media (min-width: 768px)": {
            height: "100%",
            width: "calc(100% - 251px)",
        },
        "& .children-wrapper": {
            padding: 8,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            margin: "auto",
            "& .div-img": {
                margin: "auto",
            },
            "@media (min-width: 768px)": {
                padding: 24,
            },
            a: {
                textDecoration: "none",
            },
        },
    },
    boxContainer: {
        display: "flex",
        gap: 16,
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        "& .MuiStepLabel-labelContainer": {
            color: theme.palette.action.active,
        },
        "& .btn-import": {
            minWidth: "175px",
            width: "fit-content",
            gap: 8,
            padding: "8px",
            justifyContent: "flex-start",
            "& .MuiSvgIcon-root, & .MuiTypography-root": {
                color: theme.palette.primary.main,
            },
        },

        "& .btn-add": {
            padding: "8px 16px",
            display: "flex",
            gap: 8,
            minWidth: "auto",
            minHeight: "56px",
            "& .MuiSvgIcon-root, & .MuiTypography-root": {
                color: theme.palette.primary.main,
            },
            "&:disabled": {
                backgroundColor: "transparent",
            },
        },

        "& .btn-mobile": {
            "@media (max-width: 1023px)": {
                width: "100%",
            },
        },

        "& .table-contact": {
            width: "100%",
            "& .MuiTableCell-root": {
                padding: 0,
            },
            "& .MuiTableCell-head": {
                padding: "8px 8px",
            },
            "& .MuiTableCell-body": {
                fontSize: "16px",
                padding: "8px",
            },
            "& .btn": {
                padding: 10,
                borderRadius: "10px",
                "&.btn-cancel": {
                    color: theme.palette.primary.main,
                    background: "rgba(1, 128, 255, 0.08)",
                    border: "none",
                    minWidth: 0,
                },
                "&.btn-save": {
                    color: "white",
                    minWidth: 0,
                },
                "&.edit-btn": {
                    color: theme.palette.primary.main,
                    minWidth: 0,
                },
            },
        },
    },
    container: {
        padding: "0",
        display: "flex",
        gap: 16,
        flexDirection: "column",
        maxWidth: "100%",
        height: "100%",
        "@media (min-width: 768px)": {
            gap: 24,
        },
    },
    inputBloc: {
        alignItems: "flex-start",
        gap: 8,
        "@media (min-width: 1024px)": {
            flexDirection: "row",
            justifyContent: "space-between",
        },
        "& > .MuiFormControl-root": {
            width: "100%",
        },
    },
    wrapperBloc: {
        backgroundColor: theme.palette.common.white,
        gap: 8,
        padding: "16px",
        "@media (min-width: 1025px)": {
            flexDirection: "row",
            justifyContent: "space-between",
        },
        "& .input-business": {
            width: "100%",
            minHeight: 56,
            "@media (min-width: 1025px)": {
                maxWidth: 320,
                "& .MuiAutocomplete-root": {
                    maxWidth: "100%",
                },
            },
        },
        "& .select-lang": {
            "@media (min-width: 1025px)": {
                maxWidth: 320,
                minWidth: 190,
                width: "100%",
                marginLeft: "auto",
            },
        },
        "& .checkbox-confirm": {
            marginRight: 0,
            "@media (max-width: 1024px)": {
                alignItems: "flex-start",
            },
        },
        "& .MuiFormControlLabel-label ": {
            color: theme.palette.action.active,
        },
        "& .MuiFormControlLabel-asterisk": {
            color: "#0180FF",
        },
        "& .check-condition": {
            "@media (max-width: 1024px)": {
                alignItems: "flex-start",
            },
        },
        "& .subTitle": {
            flexDirection: "column",
            justifyContent: "center",
        },
        "&.multiple": {
            flexDirection: "column",
        },
    },
    headerBlocAction: {
        flexDirection: "row",
        padding: "8px",
        "@media (min-width: 768px)": {
            justifyContent: "end",
        },
        "& .header-btn": {
            "@media (max-width: 767px)": {
                width: "100%",
            },
        },
        "& .new-model": {
            width: "100%",
            padding: "8px 16px",
            gap: "8px",
            "@media (min-width: 1024px)": {
                width: "auto",
                height: 42,
            },
        },
    },
    stepBloc: {
        width: "100%",
        margin: "0 auto",
        paddingBottom: "16px",
        gap: 16,
        "@media (min-width: 768px)": {
            gap: 24,
        },
    },
    boxMessage: {
        margin: "0 auto",
        maxWidth: "640px",
        width: "100%",
        height: "max-content",
        boxSizing: "border-box",
        borderRadius: 8,
        padding: "16px",
        borderTop: "16px solid rgba(91, 125, 216, 0.16)",
        background: "#FFF",
        "& .preview-message": {
            p: {
                margin: 0,
            },
            "& .mention": {
                border: "none",
                padding: 0,
            },
        },
        "& .btn-review": {
            width: "100%",
            maxWidth: "95px",
            maxHeight: "85px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "12.32px",
            gap: 16,
            padding: 16,
            "&.good": {
                background: "rgba(3, 204, 108, 0.16)",
            },
            "&.bad": {
                background: "rgba(255, 196, 49, 0.16)",
            },
            "& .text-review": {
                display: "flex",
                alignItems: "center",
                height: "30px",
                fontSize: "11.923px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                "&.good": {
                    color: "#028E4B",
                },
                "&.bad": {
                    color: "#B28922",
                },
            },
        },
    },
    smsBloc: {
        width: "334px",
        minHeight: "580px",
        padding: 16,
        alignItems: "center",
        borderRadius: "32px",
        border: "4px solid rgba(91, 125, 216, 0.16)",
        background: "white",
        margin: "0px auto",
        "& .rectangle": {
            width: "130px",
            height: "24px",
            borderRadius: "32px",
            background: "rgba(91, 125, 216, 0.16)",
        },
        "& .bloc-message": {
            display: "flex",
            padding: "12px",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            width: "80%",
            alignSelf: "end",
            borderRadius: "16px 16px 8px 8px",
            background: "#0180FF",
            "& .MuiTypography-root": {
                width: "100%",
            },
            p: {
                margin: 0,
                lineBreak: "auto",
                "& .mention": {
                    border: "none",
                    padding: 0,
                },
            },
        },
    },
    noMoreCredit: {
        "& .content": {
            display: "flex",
            flexDirection: "row",

            "@media (max-width: 767px)": {
                flexDirection: "column",
            },
        },
        "& button": {
            marginLeft: "auto",
            height: 42,
            minWidth: 221,
            "@media (max-width: 767px)": {
                minWidth: "100%",
            },
        },
    },
    blocRecipient: {
        display: "flex",
        flexDirection: "row",
        gap: 16,
        "& .info-avis": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 8,
            height: "min-content",
            "@media (min-width: 768px)": {
                flexDirection: "row",
            },
            "& .bloc-header": {
                "@media (min-width: 768px)": {
                    width: "50%",
                },
            },
        },
        "& .paper-stat": {
            padding: "0px 8px",
            "@media (min-width: 768px)": {
                padding: "0px 16px",
            },
        },
        "& .bloc-left": {
            gap: 16,
            width: "100%",
            "@media (min-width: 1024px)": {
                width: "50%",
                minWidth: "50%",
            },
            "@media (min-width: 768px)": {
                gap: 24,
            },
        },
        "& .bloc-sms-preview": {
            height: "max-content",
            "@media (max-width: 1023px)": {
                margin: "inherit",
            },
        },
        "& .table-contact": {
            width: "100%",
            "& .MuiTableCell-root": {
                padding: 0,
            },
            "& .MuiTableCell-head": {
                padding: "8px 8px",
            },
            "& .MuiTableCell-body": {
                fontSize: "16px",
                padding: "16px 8px",
            },
        },
        "@media (min-width: 768px)": {
            gap: 24,
        },
        "@media (max-width: 1023px)": {
            flexDirection: "column-reverse",
        },
    },
    stepper: {
        maxWidth: "238px",
        minWidth: "238px",
        ".MuiStepLabel-iconContainer": {
            padding: 0,
        },
        ".MuiStepIcon-root.Mui-active, .MuiStepIcon-root.Mui-completed": {
            color: theme.palette.secondary.main,
        },
    },
    "firstStep-action": {
        gap: 8,
        flexDirection: "row",
        padding: "16px",

        "@media (min-width: 768px)": {
            gap: 16,
            justifyContent: "flex-end",
        },
        "& button": {
            width: "100%",

            "@media (min-width: 768px)": {
                maxWidth: "50%",
            },
        },
    },
    initialWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "0",
        padding: 0,
        "& .campaign-action": {
            "@media (max-width: 1024px)": {
                flexDirection: "column-reverse",
                gap: 16,
            },
        },
    },
    tableWrapper: {
        "& .MuiTableCell-head": {
            padding: "8px",
        },
        "& .table-action-wrapper": {
            gap: "8px",
            display: "flex",
            flexDirection: "row-reverse",
            "& button": {
                padding: 0,
                width: "24px",
                height: "24px",
            },
        },
        " & .table-contact .btn": {
            padding: 0,
            width: "24px",
            height: "24px",
        },
    },
}))

export const radioTabButtonSx: SxProps = {
    display: "flex",
    alignItems: "center",
    gap: 2,
    "@media (min-width: 768px)": {
        maxWidth: 350,
    },
    "& .MuiTypography-root": {
        textWrap: "nowrap",
        margin: 0,
    },
}

export default useStyles
