import Icon from "@/components/base/Icon"
import useQuerySocial from "@/hooks/social/useQuerySocial"
import useResponsive from "@/hooks/use-responsive"
import SocialStore from "@/store/social"
import { DateScheduler, SchedulerObject } from "@/types/scheduler"
import { Button, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useCallback, useMemo, useState } from "react"

type SchedulerItemProps = {
    date: DateScheduler
    events: SchedulerObject
}

export const SchedulerItemSocial = ({ date, events }: SchedulerItemProps) => {
    const { isMobile } = useResponsive()
    const { createPost } = useQuerySocial()
    const { flushTempPost } = SocialStore()
    const [showIconAddPost, setShowIconAddPost] = useState<boolean>(false)
    const formattedDate = useMemo(() => {
        return `${date.year}-${date.month + 1}-${date.day}`
    }, [date])

    const getData = useCallback(
        (data) => {
            if (data && data.render) {
                return data.render({ ...data.renderParams, publishAt: date })
            }
            return null
        },
        [date]
    )
    const handleShowIconAddPost = (value: boolean) => {
        setShowIconAddPost(value)
    }

    const isAfterToday = useMemo(() => {
        const day = formattedDate
        return dayjs(day).isAfter(dayjs()) || dayjs(day).isSame(dayjs(), "day")
    }, [formattedDate])

    const colorOfDay = useMemo(() => {
        if (date.isCurrentDay) return "common.white"
        if (isAfterToday) return "text.primary"
        return "text.disabled"
    }, [date, isAfterToday])

    const handleCreatePost = useCallback(() => {
        createPost(true, dayjs(formattedDate).format("YYYY-MM-DD"))
        flushTempPost()
    }, [createPost, formattedDate, flushTempPost])

    const isNotEmptyBloc = useMemo(
        () => getData(events[`${date.year}-${date.month + 1}-${date.day}`]),
        [events, date, getData]
    )

    return (
        <Stack className="bloc-date" key={date.day} gap={1}>
            {date.isCurrentMonth && (
                <Stack
                    flexGrow={"1"}
                    justifyContent={isMobile ? "center" : "initial"}
                    paddingY={isMobile ? 3 : "initial"}
                    paddingX={isMobile ? 2 : "initial"}
                    gap={1}
                    onMouseOver={() => handleShowIconAddPost(true)}
                    onMouseLeave={() => handleShowIconAddPost(false)}
                    onClick={() => !isNotEmptyBloc && isAfterToday && handleCreatePost()}
                >
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        onClick={() => isAfterToday && handleCreatePost()}
                    >
                        <Typography
                            variant="body1"
                            fontSize={12}
                            className="date-label"
                            color={colorOfDay}
                            sx={{
                                backgroundColor: date.isCurrentDay ? "primary.main" : "transparent",
                            }}
                        >
                            {date.day}
                        </Typography>
                        {showIconAddPost && isAfterToday && (
                            <Button variant="contained" className="btn-add-post" onClick={handleCreatePost}>
                                <Icon name="plus" />
                            </Button>
                        )}
                    </Stack>
                    {getData(events[`${date.year}-${date.month + 1}-${date.day}`])}
                </Stack>
            )}
        </Stack>
    )
}
